<template>
  <div id="addVolunteerContainer" class="relative">

    <div>

    </div>

  </div>
</template>

<script>

  import vSelect from 'vue-select'
  import ReservationsList from '../../Sopotniki/reservations/ReservationsList';

  export default {
    name: 'travel-order',

    components: {
      ReservationsList,
      'v-select': vSelect
    },
    props: {

    },
    data () {
      return {

      }
    },

    computed: {},

    mounted () {
      const _this = this;


    },


    methods: {


    }
  }
</script>

