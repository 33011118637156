<template>
  <div id="travelOrdersContainer">
    <vs-breadcrumb class="mb-5">
      <li>
        <router-link to="/">Domov</router-link>
        <span class="vs-breadcrum--separator">/</span></li>
      <li aria-current="page" class="active">Službeni potni nalogi</li>
    </vs-breadcrumb>

    <vs-row>
      <vs-col vs-type="flex" vs-justify="start" vs-align="start" vs-w="6">
        <vs-button color="primary" size="small" class="mt-1 mr-2" icon="keyboard_arrow_left"
                   @click="() => {selectedDate = moment(selectedDate).subtract(1, 'M').toDate(); onDateChange()}"></vs-button>
        <datepicker :minimumView="'month'" :maximumView="'year'" :language="slSI" :format="customDateFormatter"
                    :monday-first="true"
                    @input="onDateChange" v-model="selectedDate"></datepicker>
        <vs-button color="primary" size="small " class="mt-1 ml-2" icon="keyboard_arrow_right"
                   @click="() => {selectedDate = moment(selectedDate).add(1, 'M').toDate(); onDateChange()}"></vs-button>

        <vs-checkbox class="mt-2 ml-20" v-model="showOnlyMy" @change="getTravelOrders" v-if="$globalFunctions.getCurrentUser().role === 1">Prikaži samo moje naloge za službene poti</vs-checkbox>
      </vs-col>
    </vs-row>


    <div v-if="!travelOrdersData || travelOrdersData.length === 0" class="mt-2">
      <vs-row>
        <vs-col vs-type="flex" vs-justify="start" vs-align="start" vs-w="6">
          <em class="mt-5">Ni vnosov za prikaz</em>
        </vs-col>
      </vs-row>
    </div>

    <vs-table v-if="travelOrdersData && travelOrdersData.length > 0" :data="travelOrdersData" v-model="selected" @selected="showtravelOrdersDetails"
              :pagination="false" multiple search>
      <template slot="thead">
        <vs-th sort-key="date">
          Datum
        </vs-th>
        <vs-th sort-key="unit_id">
          Enota
        </vs-th>
        <vs-th sort-key="volunteers_id">
          Potnik
        </vs-th>
        <vs-th sort-key="destination_address_id">
          Destinacija
        </vs-th>
        <vs-th sort-key="type">
          Namen
        </vs-th>
        <vs-th>
          Opomba
        </vs-th>
        <vs-th>
          Akcije
        </vs-th>
      </template>



      <template slot-scope="{data}">
        <vs-tr :key="'active_' + index" v-for="(tr, index) in data" v-if="data[index].disabled === 0" :data="tr">

          <vs-td :data="data[index].departure_time">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
              {{ moment(data[index].departure_time).format('DD. MM. YYYY') }}
            </vs-col>
          </vs-td>

          <vs-td :data="data[index].unit">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
              {{ data[index].unit.name }}
            </vs-col>
          </vs-td>

          <vs-td :data="data[index].volunteer">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
              <vs-avatar size="large"
                         v-if="data[index].volunteer"
                         :src="$globalFunctions.getUserAvatar(data[index].volunteer.profile_image)"/>
              <div class="ml-2" v-if="data[index].volunteer">{{
                  data[index].volunteer.first_name + ' ' +
                  data[index].volunteer.last_name
                }}
              </div>
            </vs-col>
          </vs-td>

          <vs-td :data="data[index].destination">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
              {{ data[index].destination.quick_search }}
            </vs-col>
          </vs-td>

          <vs-td :data="data[index].type">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
              {{ data[index].type ? data[index].type.name : '/' }}
            </vs-col>
          </vs-td>

          <vs-td :data="data[index].notes">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">

              <vs-tooltip :text="data[index].notes" title="Opomba" :key="data[index].id"
                          v-if="data[index].notes" position="left">
                <vs-avatar icon="note"/>
                <span>&nbsp;</span>
              </vs-tooltip>
              <div v-else>
                <em>/</em>
              </div>

            </vs-col>
          </vs-td>

          <vs-td :data="data[index]" @mousedown.stop="stopTheEvent">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">

              <vs-button type="filled" color="success" icon="content_copy" size="small" class="vs-lg-12"
                         @click.stop="duplicateTravelOrder(data[index])">Podvoji
              </vs-button>

              <vs-button type="filled" color="primary" icon="edit" size="small" class="vs-lg-12 ml-5"
                         @click.stop="editTravelOrder(data[index])">Uredi
              </vs-button>

              <vs-button type="filled" color="danger" icon="delete" size="small" class="vs-lg-12 ml-5"
                         @click.stop="beforeDeleteTravelOrder(data[index])">Izbriši
              </vs-button>

            </vs-col>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <div id="parentx">
      <vs-sidebar position-right parent="body" default-index="1" color="primary" class="unitSidebar" spacer
                  v-model="sidebarActive">

        <TravelOrder :travelOrderData="selectedTravelOrder" v-if="selectedTravelOrder && mode === 'travelOrder'"
                     @cancel="canceltravelOrderDetails()"/>

      </vs-sidebar>
    </div>


    <vs-button radius size="large" color="success" icon-pack="feather" icon="icon-plus-circle" id="addFabBtn"
               title="Dodaj nov nalog za službeno pot" @click.stop="addTravelOrder()"></vs-button>

    <TravelOrderPrint :selectedTravelOrders="selected" :month="moment(selectedDate).format('MMMM')" :year="moment(selectedDate).format('YYYY')" @cancel="canceltravelOrderDetails()"/>

  </div>
</template>

<script>
import Vue from 'vue';
import Unit from '../../components/Sopotniki/units/Unit';
import Datepicker from 'vuejs-datepicker';
import {slSI} from 'vuejs-datepicker/dist/locale';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from 'html-to-pdfmake';
import ReplaceVolunteer from '../../components/Sopotniki/reports/ReplaceVolunteer';
import AddVolunteer from '../../components/Sopotniki/reports/AddVolunteer';
import TravelOrder from '../../components/Sopotniki/travelOrders/TravelOrder';
import TravelOrderPrint from '../../components/Sopotniki/travelOrders/TravelOrderPrint';



export default {
  name: 'travelOrders',

  components: {
    AddVolunteer,
    ReplaceVolunteer,
    TravelOrder,
    Datepicker,
    TravelOrderPrint
  },

  data() {
    return {
      slSI,
      travelOrdersData: null,
      search: '',
      sidebarActive: false,
      selectedDate: new Date(),
      selectedTravelOrder: null,
      mode: null,
      selected:[],
      showOnlyMy: false
    }
  },

  computed: {},

  async mounted() {
    const _this = this;
    this.selectedDate = await this.$store.getters.getSelectedDateForTravelOrders;
    _this.getTravelOrders();
  },

  beforeRouteLeave(to, from, next) {
    console.log('beforeRouteLeave');
    const _this = this;
    _this.sidebarActive = false;
    setTimeout(() => {
      next()
    }, 100);
  },

  methods: {
    getTravelOrders() {
      const _this = this;

      _this.$vs.loading();

      const month = _this.moment(_this.selectedDate).month() + 1;
      const year = _this.moment(_this.selectedDate).year();

      console.log('month', month, ', year', year);

      const url = `${Vue.prototype.$config.api.productionOld}/v2/customReport/${year}/${month - 1}`;

      Vue.prototype.$http.get(url)
        .then((res) => {

          let travelOrders = null;

          // WORKAROUND: Hide Marko's and Anu's travel orders
          if(_this.$globalFunctions.getCurrentUser().volunteer.id === 1 || _this.$globalFunctions.getCurrentUser().volunteer.id === 11) {
            travelOrders = res.data.data;
          } else {
            travelOrders = _.filter(res.data.data, function (val, key) {
              if (val.volunteer.id != 1 && val.volunteer.id != 11) {
                return val
              }
            });
          }

          if(_this.showOnlyMy) {
            travelOrders = _.filter(travelOrders, function (val, key) {
              if (val.volunteer.id === _this.$globalFunctions.getCurrentUser().volunteer.id) {
                return val
              }
            });
          }

          _this.travelOrdersData = _.sortBy(travelOrders, function (o) {
            return o.departure_time;
          });

          _this.$vs.loading.close();
        })
        .catch((error) => {
          console.log('error', error);
          _this.$vs.loading.close();
          return false
        });
    },

    showtravelOrdersDetails(data) {
      const _this = this;

      console.log('HERE!', data);


      // _this.selectedTravelOrder = data;
      // _this.mode = 'travelOrder';
      // _this.sidebarActive = true;
    },

    onDateChange() {
      this.$store.dispatch('setSelectedDateForTravelOrders', this.selectedDate);
      this.getTravelOrders();
    },

    customDateFormatter(date) {
      return this.moment(date).format('MMMM, YYYY');
    },

    addTravelOrder() {
      const _this = this;

      console.log("addTravelOrder");
      _this.$router.push({name: 'Sluzbeni potni nalog'});
    },

    beforeDeleteTravelOrder(data) {
      const _this = this;

      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Potrditev izbire',
        text: `Ali si prepričan, da želiš izbrisati dnevni potni nalog za dan ${_this.moment(data.date).format('dddd, DD. MM. YYYY')}? Po potrditvi bo službeni potni nalog trajno izbrisan.`,
        accept: () => {
          _this.$vs.loading();
          _this.deleteTravelOrder(data);
        }
      })

    },

    deleteTravelOrder(data) {
      const _this = this;

      let travelOrder = _.omit(data, ['disabled', 'deleted', 'created_at', 'updated_at', 'customer', 'unit', 'volunteer', 'purpose', 'car']);
      travelOrder.deleted = true;
      travelOrder.type = data.type.id;

      Vue.prototype.$http.post(Vue.prototype.$config.api.productionOld + 'v2/customReport/', travelOrder)
        .then((res) => {

          console.log("res", res);
          _this.$vs.notify({color:'success',title:'Službeni potni nalog uspešno izbrisan!',text:'', position: 'top-right'});
          _this.$vs.loading.close();
          _this.getTravelOrders();

        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });

      // TODO: implement proper DELETE request
      // Vue.prototype.$http.delete(`${Vue.prototype.$config.api.productionOld}v2/customReportTypes/${data.id}`)
      //   .then(res => {
      //
      //     _this.$vs.loading.close();
      //     _this.getTravelOrders();
      //
      //   })
      //   .catch(error => {
      //     console.log(error);
      //   });
    },

    duplicateTravelOrder(data) {
      const _this = this;
      console.log("duplicateTravelOrder");

      let travelOrder = _.omit(data, ['created_at', 'updated_at', 'customer', 'unit', 'volunteer', 'purpose', 'car']);
      travelOrder.id = null;
      travelOrder.type = data.type.id;

      Vue.prototype.$http.post(Vue.prototype.$config.api.productionOld + 'v2/customReport/', travelOrder)
        .then((res) => {

          console.log("res", res);
          _this.$vs.notify({color:'success',title:'Službeni potni nalog uspešno podvojen!',text:'', position: 'top-right'});
          _this.$vs.loading.close();
          _this.$router.push({name: 'Sluzbeni potni nalog', params: { travelOrderId: res.data.data.id, createdViaDuplicateMethod: true}});

        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });

    },

    editTravelOrder(data) {
      const _this = this;
      console.log("editTravelOrder");
      _this.$router.push({name: 'Sluzbeni potni nalog', params: { travelOrderId: data.id}});
    },



  }
}
</script>

